import { For, Component, Show, ErrorBoundary } from "solid-js";
import { useSiteContext } from "~/utils/contexts";
import { PHOTOFRAME, GALLERYFRAME, MOULDING, MIRROR } from "~/utils/products";
import { Carousel } from "./";
import { Product } from "../product";
import { EventType } from "@solid-primitives/analytics";

export const BaseProductSlider: Component<{
  title?: string;
  imageType?: string;
  href?: string;
  hrefLabel?: string;
  productList: Array<any>;
  productListWithType?: [];
  analyticsListName?: string;
}> = (props) => {
  const { track, trackFBQ } = useSiteContext();
  const imagepath = import.meta.env.VITE_ROMA_CDN;
  const imagePaths: Record<string, string> = {
    [PHOTOFRAME]: "photo-frames",
    [GALLERYFRAME]: "gallery-frames",
    [MOULDING]: "mouldings",
    [MIRROR]: "mouldings",
  };

  return (
    <Show when={props.productList}>
      <Carousel
        title={props.title}
        href={props.href}
        hrefLabel={props.hrefLabel}
      >
        <For each={props.productList}>
          {(item: any) => (
            // TODO - Error-Boundary
            // <ErrorBoundary fallback="boop">
            <Product
              class="snap-start pl-2 pr-2 first:ml-0 last:mr-0 shrink-0  w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              sku={item.data.SKU}
              width={item.data.Width}
              height={item.data.Height}
              rabbet={item.data.Rabbet}
              collection={item.data.Collection}
              colour={item.data.ColourDescription}
              image1={`${imagepath}/${imagePaths[item.type ?? "moulding"]}/${
                item.data.SKU
              }-1.png`}
              image2={`${imagepath}/${imagePaths[item.type ?? "moulding"]}/${
                item.data.SKU
              }-2.png`}
              is_new={item.data.IsNew}
              is_discontinued={item.data.Discontinued}
              is_discontinuing={item.data.Discontinuing}
              is_coming_soon={item.data.ComingSoon}
              arrival_date={item.data.ArrivalDate}
              hover={false}
              type={item.type}
              onClick={
                props.analyticsListName
                  ? () => {
                      track(EventType.Event, {
                        category: "select_item",
                        //@ts-expect-error
                        item_list_name: props.analyticsListName,
                        items: [
                          {
                            item_id: item.data.SKU,
                            item_name: item.data.Name,
                            item_category: item.type,
                            item_category2: item.data.Category,
                            item_cateogry3: item.data.Collection,
                          },
                        ],
                      });
                    }
                  : undefined
              }
            />
            // </ErrorBoundary>
          )}
        </For>
      </Carousel>
    </Show>
  );
};
